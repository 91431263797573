import styled from "styled-components";

export const AccountText = styled.p`
  display: flex;
  flex: 1.3;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  min-width: 105px;
`;

export const PaymentText = styled(AccountText)`
  flex: 4;
`;

export const TransactionText = styled(AccountText)`
  flex: 3.5;

  @media (max-width: 500px) {
    flex: 1.7;
    min-width: 50px;
  }
`;

export const TypeText = styled(AccountText)`
  flex: 2;
`;

export const TimeText = styled(AccountText)`
  flex: 1.4;
  min-width: 53px;
`;

export const AccountWrapper = styled.div`
  display: flex;
  flex: 1.3;
  min-width: 105px;
`;

export const PaymentWrapper = styled(AccountWrapper)`
  display: inline;
  flex: 4;

  @media (max-width: 450px) {
    width: 80px;
  }
`;

export const TransactionWrapper = styled(AccountWrapper)`
  display: flex;
  flex: 3.5;

  @media (max-width: 500px) {
    flex: 1.7;
    min-width: 50px;
  }
`;

export const TypeWrapper = styled(AccountWrapper)`
  display: flex;
  flex: 2;
`;

export const TimeWrapper = styled(AccountWrapper)`
  display: flex;
  flex: 1.4;
  min-width: 53px;
`;
