import { Link } from "react-router-dom";
import styled from "styled-components";

import { LinkVariant } from "./AccountLink";

export const LinkContainer = styled.span<{ $variant: LinkVariant; $first?: boolean; $last?: boolean }>`
  margin-left: ${({ theme, $first }) => ($first ? 0 : theme.spacing(1.7))};
  margin-right: ${({ theme, $last }) => ($last ? 0 : theme.spacing(1.7))};

  ${({ theme, $variant }) => {
    if ($variant === "button")
      return `
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${theme.palette.themeColors.backgrounds.buttonSecondary};
        min-width: 105px;
        height: ${theme.spacing(13)};
        border-radius: 12px;
        cursor: pointer;
        padding: 0 ${theme.spacing(2)};
        margin-left: 0;
        margin-right: 0;
      `;
  }}

  &:hover {
    ${({ theme, $variant }) => {
      if ($variant === "button")
        return `
          background-color: ${theme.palette.themeColors.backgrounds.buttonSecondaryHover}`;
    }}
`;

export const LinkLabel = styled(Link)<{ $variant: LinkVariant }>`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  font-family: "Monospaced", sans-serif;
  font-weight: ${({ theme }) => theme.fontWeight.neutral};
  letter-spacing: ${({ theme }) => theme.spacing(0.3)};
  color: ${({ theme }) => theme.palette.themeColors.fonts.customLink};

  &:hover {
    ${({ theme, $variant }) =>
      $variant === "text" && `color: ${theme.palette.themeColors.fonts.viewAllButton}`};
  }

  ${({ theme, $variant }) => {
    if ($variant === "button")
      return `
        display: flex;
        align-items: center;
        text-decoration: none;
        font-size: ${theme.fontSize.s};
        font-weight: ${theme.fontWeight.semibold};
        letter-spacing: ${theme.spacing(0.3)};
        color: ${theme.palette.themeColors.fonts.secondary};
      `;
  }};
`;
