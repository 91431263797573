import styled from "styled-components";

export const AccountText = styled.p`
  display: flex;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  padding-left: ${({ theme }) => theme.spacing(2)};
  min-width: 105px;
`;

export const OperationText = styled(AccountText)`
  flex: 3;
  padding: 0;
`;

export const TransactionText = styled(AccountText)`
  flex: 2;
  padding: 0;
  min-width: 100px;
`;

export const TypeText = styled(AccountText)`
  flex: 2;
  padding: 0;
`;

export const TimeText = styled(AccountText)`
  flex: 1.4;
  padding: 0;

  @media (max-width: 520px) {
    min-width: 56px;
  }
`;

export const JsonText = styled(AccountText)`
  flex: 1;
  padding: 0;
`;

export const AccountButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 105px;
`;

export const TitleWrapper = styled(AccountButtonWrapper)`
  display: inline;
  align-items: center;
  flex: 3;
  font-size: ${({ theme }) => theme.fontSize.base};

  @media (max-width: 450px) {
    width: 80px;
  }
`;

export const TransactionWrapper = styled(TitleWrapper)`
  flex: 2;
  min-width: 100px;
`;

export const TypeWrapper = styled(TitleWrapper)`
  flex: 2;
  min-width: 50px;
  display: inline;

  white-space: normal;
  word-wrap: break-word;
  overflow-wrap: break-word;
`;

export const TimeWrapper = styled(TitleWrapper)`
  flex: 1.4;

  @media (max-width: 520px) {
    min-width: 56px;
  }
`;

export const JsonWrapper = styled(TitleWrapper)`
  flex: 1;
`;
