import styled from "styled-components";

export const HashMark = styled.p<{ $expanded?: boolean }>`
  display: block;
  flex: 1;
  color: ${({ theme }) => theme.palette.themeColors.fonts.tableHeader};
  font-weight: ${({ theme }) => theme.fontWeight.bold};
  ${({ $expanded }) => $expanded && "flex: 4"};

  min-width: 80px;
`;

export const SourceText = styled(HashMark)`
  flex: 1.05;
  min-width: 105px;
`;

export const BlockText = styled(HashMark)<{ $expanded?: boolean }>`
  ${({ $expanded }) => $expanded && "flex: 0.7"};
`;

export const OpsText = styled(HashMark)<{ $expanded?: boolean }>`
  flex: 0.5;
  ${({ $expanded }) => $expanded && "flex: 0.7"};

  @media (max-width: 650px) {
    min-width: 35px;
  }
`;

export const TimeText = styled(HashMark)<{ $expanded?: boolean }>`
  flex: 1.1;
  ${({ $expanded }) => $expanded && "flex: 0.7"};
`;

export const HashWrapper = styled.div<{ $expanded?: boolean }>`
  display: block;
  flex: 1;
  ${({ $expanded }) => $expanded && "flex: 4"};

  min-width: 80px;
`;

export const SourceWrapper = styled(HashWrapper)`
  display: flex;
  flex: 1.05;
  min-width: 105px;
`;

export const BlockWrapper = styled(HashWrapper)<{ $expanded?: boolean }>`
  display: flex;
  ${({ $expanded }) => $expanded && "flex: 0.7"};
`;

export const OperationWrapper = styled(HashWrapper)<{ $expanded?: boolean }>`
  display: flex;
  flex: 0.5;
  ${({ $expanded }) => $expanded && "flex: 0.7"};

  @media (max-width: 650px) {
    min-width: 35px;
  }
`;

export const TimeWrapper = styled(HashWrapper)<{ $expanded?: boolean }>`
  display: flex;
  flex: 1.1;
  ${({ $expanded }) => $expanded && "flex: 0.7"};
`;
