import { ServerApi } from "@stellar/stellar-sdk/lib/horizon";
import React from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";

import { useStellarContext } from "../../contexts/StellarContextProvider";
import { PaymentsCombined } from "../../data/services/useGetPayments";
import { getSourceAddressFrom, shortenString } from "../../utils/links";
import AccountLink from "../AccountLink/AccountLink";
import CustomInternalLink from "../CustomInternalLink/CustomInternalLink";
import OperationTitle from "../OperationTitle/OperationTitle";
import { ListContainer, ListRow, TableTitles } from "../ReusableStyles";
import * as S from "./PaymentsTable.styles";

type TransactionsTableProps = {
  payments?: ServerApi.CollectionPage<PaymentsCombined>;
};

const PaymentsTable = (p: TransactionsTableProps) => {
  const { environment } = useParams<{ environment: string }>();
  const { screenWidth } = useStellarContext();

  const { t } = useTranslation();

  return (
    <>
      <TableTitles>
        <S.AccountText>{t("account")}</S.AccountText>
        <S.PaymentText>{t("payment")}</S.PaymentText>
        {screenWidth > 425 && (
          <S.TransactionText>{screenWidth < 500 ? t("tsx") : t("transaction")}</S.TransactionText>
        )}
        {screenWidth > 570 && <S.TypeText>{t("type")}</S.TypeText>}
        <S.TimeText>{t("time")}</S.TimeText>
      </TableTitles>
      <ListContainer>
        {p.payments?.records.map((payment, index) => {
          const sourceAddress = getSourceAddressFrom(payment);

          return (
            <ListRow $isLast={index === (p.payments?.records.length || 0) - 1} key={payment.id}>
              <S.AccountWrapper>
                {payment.type === "account_merge" ? (
                  <span title={sourceAddress}>{sourceAddress.slice(0, 4)}</span>
                ) : (
                  <AccountLink accountAddress={sourceAddress} variant="button" />
                )}
              </S.AccountWrapper>
              <S.PaymentWrapper>
                <OperationTitle operation={payment} />
              </S.PaymentWrapper>
              {screenWidth > 425 && (
                <S.TransactionWrapper>
                  <CustomInternalLink
                    to={`/${environment}/transactions/${payment.transaction_hash}`}
                    title={payment.transaction_hash}
                  >
                    {shortenString(
                      payment.transaction_hash,
                      screenWidth < 930 ? (screenWidth < 690 ? (screenWidth < 500 ? 8 : 15) : 20) : 30
                    )}
                  </CustomInternalLink>
                </S.TransactionWrapper>
              )}
              {screenWidth > 570 && <S.TypeWrapper>{payment.type}</S.TypeWrapper>}
              <S.TimeWrapper>
                <CustomInternalLink to={`/${environment}/transactions/${payment.transaction_hash}`}>
                  <ReactTimeAgo date={new Date(payment.created_at)} locale="en-US" timeStyle="round" />
                </CustomInternalLink>
              </S.TimeWrapper>
            </ListRow>
          );
        })}
      </ListContainer>
    </>
  );
};

export default PaymentsTable;
